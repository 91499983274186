@import './style/scss//reset';
@import './style/scss//palette';
@import './style/scss//extends';

html, body, main#root {
    @extend %setFullScreen;
    font-size: 1.125rem;
    font-family: 'Roboto', 'sans-serif';
    overflow: hidden;
    background: #eeeeee;
}

a {
    text-decoration: none!important;
}

/* 
*   Login Component
*/

.login__container {
    @extend %setFullScreen;
    display: flex;
    & div.login__background__image {
        width: 100%;
        background:rgba(0,0,0,0.4) url('./images/login-image.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        background-blend-mode: darken;
    }
}

@media (max-width: 600px) {
    html, body, main#root {
        font-size: 1rem;
    }
}

